//
// Base styles
//

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  @include border-radius($card-border-radius);

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .list-group:first-child {
    .list-group-item:first-child {
      @include border-top-radius($card-border-radius);
    }
  }

  > .list-group:last-child {
    .list-group-item:last-child {
      @include border-bottom-radius($card-border-radius);
    }
  }
}

.card-body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  padding: $card-spacer-x;
  color: $card-color;
}

.card-title {
  margin-bottom: $card-spacer-y;
}

.card-subtitle {
  margin-top: -$card-spacer-y / 2;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link {
  @include hover {
    text-decoration: none;
  }

  + .card-link {
    margin-left: $card-spacer-x;
  }
}

//
// Optional textual caps
//

.card-header {
  padding: $card-spacer-y $card-spacer-x;
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  color: $card-cap-color;
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;

  &:first-child {
    @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
  }

  + .list-group {
    .list-group-item:first-child {
      border-top: 0;
    }
  }
}

.card-footer {
  padding: $card-spacer-y $card-spacer-x;
  background-color: $card-cap-bg;
  border-top: $card-border-width solid $card-border-color;

  &:last-child {
    @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
  }
}


//
// Header navs
//

.card-header-tabs {
  margin-right: -$card-spacer-x / 2;
  margin-bottom: -$card-spacer-y;
  margin-left: -$card-spacer-x / 2;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -$card-spacer-x / 2;
  margin-left: -$card-spacer-x / 2;
}

// Card image
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-img-overlay-padding;
}

.card-img {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-radius($card-inner-border-radius);
}

// Card image caps
.card-img-top {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-top-radius($card-inner-border-radius);
}

.card-img-bottom {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-bottom-radius($card-inner-border-radius);
}


// Card deck

.card-deck {
  display: flex;
  flex-direction: column;

  .card {
    margin-bottom: $card-deck-margin;
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;
    margin-right: -$card-deck-margin;
    margin-left: -$card-deck-margin;

    .card {
      display: flex;
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      flex-direction: column;
      margin-right: $card-deck-margin;
      margin-bottom: 0; // Override the default
      margin-left: $card-deck-margin;
    }
  }
}


//
// Card groups
//

.card-group {
  display: flex;
  flex-direction: column;

  // The child selector allows nested `.card` within `.card-group`
  // to display properly.
  > .card {
    margin-bottom: $card-group-margin;
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;
    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    > .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-bottom: 0;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:not(:last-child) {
          @include border-right-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-blacklist
            border-top-right-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-blacklist
            border-bottom-right-radius: 0;
          }
        }

        &:not(:first-child) {
          @include border-left-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-blacklist
            border-top-left-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-blacklist
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}


//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $card-columns-margin;
  }

  @include media-breakpoint-up(sm) {
    column-count: $card-columns-count;
    column-gap: $card-columns-gap;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}


//
// Accordion
//

.accordion {
  > .card {
    overflow: hidden;

    &:not(:first-of-type) {
      .card-header:first-child {
        @include border-radius(0);
      }

      &:not(:last-of-type) {
        border-bottom: 0;
        @include border-radius(0);
      }
    }

    &:first-of-type {
      border-bottom: 0;
      @include border-bottom-radius(0);
    }

    &:last-of-type {
      @include border-top-radius(0);
    }

    .card-header {
      margin-bottom: -$card-border-width;
    }
  }
}


// .computersurface-left{
//   float: left;
// width: 1000px;
// height: 400px;
// background-color: #fff;
// border-radius: 8px;
// box-shadow: 0 0 13px 0 rgba(82,63,105,.05);

// }


// .computersurface-head{
// float: left;
//   width: 1000px;
//   height: 60px;
//   background-color: #fff;
//   border-bottom: 1px solid #ebedf2;
//   border-radius: 8px;

// }


// .computersurface-right{
//   float: right;
// width: 450px;
// height: 400px;
// background-color: yellow;


// }

.instrumentname-span{


  line-height: 35px;
}

#box {
  width: 400px;
  height: 500px;
  // background-color: blue;

  line-height: 30px;
}
#box::-webkit-scrollbar {
  display: none;
}

.Roll {
  width: 417px;
  height: 500px;
  line-height: 30px;
  // background-color: blue;
  text-align: center;
  overflow-y: scroll;
}

.Roll p{
  padding-top: 2px;
  cursor: pointer;
  // color: #5d78ff!important;
  font-size: 15px;
    font-weight: 500;
}

.Roll p:hover{
background-color:#F3F3F3;
  cursor: pointer;
}

.system-head{
  height: 50px;
  width: 300px;
// background-color: blue;
text-align: center;
  margin:0 auto;
}

.system-head span{
text-align: center;
  line-height: 50px;
  // color: red;
  font-size: 22px;
  font-weight: 700;

}

.system-time{
// background-color: red;
width: 100px;
height: 25px;
text-align: center;
  margin:0 auto;


}

.system-contact{
// background-color: yellow;
  width: 90%;
  // height: 700px;
  text-align: center;
    margin:0 auto;
    margin-top: 20px;


}

.system-contact {
  text-align: left;

}



.contact-padding{
  font-size: 25px;
    font-weight: 500;
padding-top: 20px;

}

.contact-end{

  width: 100px;
  height: 50px;
  // background-color: blue;
  margin-top: 20px;
  margin-right: 20px;
  text-align: center;
  float: right;
}

.ybox-right{
  // background-color: black;
  float: right;
  margin-right: 5px;

}

.media-body a{
  text-decoration: none;

}
.xxxxx-sss{
  margin-top: 5px!important;
color: red;
}

.appid-center{
  height: 30px;
  width: 300px;
  // background-color: red;
  line-height: 30px;
}

.todayboxs {
  margin: 24px 0;
}
.flex-items {
  display: flex;
}

.todayboxs .flex-item {
  background-color: #fff;
  width: 100%;
  height: 104px;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-right: 24px;
  color: #333;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
}
.flex-items .flex-item {
  flex: 1;
}

.todayboxs .num {
  font-size: 24px;
  text-decoration: none;
}


.todayboxs .title {
  color: #747474;
}

.em, i {
  font-style: normal;
}

.img {
  vertical-align: middle;
  border-style: none;
}

.todayboxs a{
  text-decoration: none;

}

.row-panel {
  margin-bottom: 24px;
  display: flex;
}

.row-panel .row-panel-7 {
  width: 50%;
  flex-shrink: 1;
}
.mypanel {
  position: relative;
  background-color: #fff;
  padding: 0 20px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
}

.mypanel .mypanel-heading {
  font-size: 15px;
  font-weight: 600;
  border-bottom: 1px solid #f0f0f0;
  height: 48px;
  line-height: 48px;
}
.tasks .flex-item {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
  border-bottom: 1px solid #f0f0f0;
  color: #747474;
}
.flex-items .flex-item {
  flex: 1;
}

.tasks .num.hasnum {
  color: #436be6;
}
.tasks .num {
  font-size: 15px;
  color: #9e9e9e;
  font-weight: 600;
}

.row-panel .row-panel-5 {
  width: 50%;
  margin-left: 24px;
  flex-shrink: 0;
}

.mypanel {
  position: relative;
  background-color: #fff;
  padding: 0 20px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
}

.mypanel .mypanel-heading {
  font-size: 15px;
  font-weight: 600;
  border-bottom: 1px solid #f0f0f0;
  height: 48px;
  line-height: 48px;
}

.row-panel {
  margin-bottom: 24px;
  display: flex;
}
.row-panel .row-panel-12 {
  width: 100%;
  flex-shrink: 1;
}
.row-panel .row-panel-7 {
  width: 50%;
  flex-shrink: 1;
}
.row-panel .row-panel-5 {
  width: 50%;
  margin-left: 24px;
  flex-shrink: 0;
}

.mypanel {
  position: relative;
  background-color: #fff;
  padding: 0 20px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
}
.mypanel .mypanel-heading {
  font-size: 15px;
  font-weight: 600;
  border-bottom: 1px solid #f0f0f0;
  height: 48px;
  line-height: 48px;
}
.mypanel-body {
  padding: 20px 0;
}
.flex-items {
  display: flex;
}

.quick-nav .flex-item, .total-mes .flex-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 68px;
  margin-bottom: 20px;
  margin-right: 20px;
  color: #747474;
}
.flex-items .flex-item {
  flex: 1;
}

.total-mes .num {
  height: 48px;
  line-height: 48px;
  font-size: 15px;
  color: #333;
  font-weight: 600;
}

.quick-nav a{
text-decoration: none;


}

.quick-nav a:hover{
  color: rgb(46, 173, 131);
}

.btn-primary {
  background-color: #436be5;
  border-color: #436be5;
}
.btn-primary a {
  color: #fff;
  text-decoration: none;
}


.onoffswitch-center{
position: absolute;
left: 78px;

}

.nav-tabs-custom {
  height: 64px;
  line-height: 64px;
}

.nav {
  padding-left: 0;
  margin-top: -30px;
  margin-bottom: 10px;
  list-style: none;
}

。ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.nav-tabs-custom>li {
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  margin: 0 15px;
}
.nav>li {
  position: relative;
  display: block;
}
ul, li {
  list-style: none;
  padding: 0;
  margin: 0;
  
}

.nav-tabs-custom>li.active>a {
  color: #436be5;
  border-bottom: 2px solid #436be5;
}
.nav-tabs-custom>li>a {
  height: 64px;
  line-height: 64px;
  color: #999;
  padding: 0;
}
.nav>li>a {
  position: relative;
  display: block;
  padding: 10px 15px;
}


.active a {
  background-color: transparent;
  text-decoration: none;
}

.table .text-order {
  width: 80px;
  text-align: center;
  margin-left: 15px;
  // border-color: red;
  border:1px dashed rgb(179, 173, 173);
  
}

// .tableborder{
//   border: 1px solid blue;
// }


//
.custom-inline .form-group .form-control {
  padding: 0 10px;
}
.custom-inline .form-control {
  height: 32px;
  width: 150px;
  border-radius: 2px;
}

.form-inline .form-control {
  display: inline-block;
  // width: auto;
  vertical-align: middle;
}

.table .text-order, .table .text-name, .text-module {
  padding: 2px 5px;
  border: 1px dashed transparent;
  outline: 0;
  border-radius: 2px;
  transition: all linear .2s;
}
.table .text-order {
  width: 50px;
  text-align: center;
  margin-left: 15px;
  border-color: #999;
}
.container .table {
  margin: 0;
  background-color: #fff;
  color: #494e52;
  font-size: 13px;
  word-break: break-all;
}

.hiMallDatagrid-cell input:hover{

  border: 1px solid red;
}
.lh20 {
  line-height: 20px!important;
  color:#f44336;
}
.overflow-name{

  display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.table-act-row {
  margin-top: -20px;
  padding: 10px 0;
}
.table-act-row .btn-group {
  margin-right: 15px;
  vertical-align: bottom;
}
.btn-group, .btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.btn-sm {
  vertical-align: baseline;
}
.btn-primary {
  background-color: #436be5;
  border-color: #436be5;
}
.btn-group-sm>.btn, .btn-sm {
  padding: 5px 15px!important;
  border-radius: 2px;
}
.pull-right {
  float: right!important;
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group>.btn:first-child {
  margin-left: 0;
}
.btn-group-vertical>.btn, .btn-group>.btn {
  position: relative;
  float: left;
}
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  // background-image: url(../img/select_03.png)!important;
  background-repeat: no-repeat!important;
  background-position: right center!important;
}

.AuiDel{
  background-color: red;
  width: 500px;
  height: 500px;
}

.footer-fff{
  background-color: #f5f5f5;
}

.btn-group-batch a:hover{
  cursor: pointer;
  color: #436be5;
}

.form-control2{
  box-shadow: 0 0 0 #000 !important;
  font-size: 0.875rem;
  transition: none;
  width: 100%;
    height: 2.1875rem;
    padding: 0.375rem 1rem;
    font-weight: 400;
    line-height: 1.52857;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dde6e9;
    border-radius: 0.25rem;

}


.upload-img .imageBox, .upload-img .upl-right {
  position: relative;
  height: 24px;
  transition: opacity .3s;
  -webkit-transition: opacity .3s;
  min-width: 106px;
}

.upload-img input.uploadFilebtn {
  position: relative;
  opacity: 0;
  filter: Alpha(opacity=0);
}
.upload-img .file {
  width: 68px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
input[type=file] {
  display: block;
}
button, input, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}