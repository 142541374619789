/* ========================================================================
     Component: bootstrap-custom
 ========================================================================== */

// Use this file to add custom css that may
// depend on Bootstrap styles



// Different container size to wrap content
.container-sm {
    max-width: map-get($container-max-widths, "sm");
    width: auto;
}

.container-md {
    max-width: map-get($container-max-widths, "md");
    width: auto;
}

.container-lg {
    max-width: map-get($container-max-widths, "lg");
    width: auto;
}

// Remove padding and collapse columns
.row-flush {
    margin: 0;
    >.col,
    >[class*="col-"] {
        padding-left: 0;
        padding-right: 0;
    }
}


// Breadcrumb variants

.breadcrumb {
    font-weight: normal;
    border-radius: 0;
    color: $text-muted;
    padding: 10px 20px;
}

.content-heading {
    // Breadcrumb below title
    .breadcrumb {
        font-size: 0.8125rem;
        margin-bottom: 0;
    }
    // Breadcrumb next to view title
    +.breadcrumb {
        margin: -25px -25px 20px -20px;
        background-color: $content-heading-bg;
        border-top: 1px solid $content-heading-border;
        border-bottom: 1px solid $content-heading-border;
    }
}

// Different size of Progress bars
$progress-height-sm: 15px;
$progress-height-xs: 8px;

.progress-sm {
    height: $progress-height-sm;
}

.progress-xs {
    height: $progress-height-xs;
}


// Extra badges

.badge-inverse {
    @include badge-variant($inverse);
}

.badge-green {
    @include badge-variant($green);
}

.badge-pink {
    @include badge-variant($pink);
}

.badge-purple {
    @include badge-variant($purple);
}


// Extra alert

.alert-purple {
    @include alert-variant($purple, $purple, #fff);
}
.alert-green {
    @include alert-variant($green, $green, #fff);
}
.alert-pink {
    @include alert-variant($pink, $pink, #fff);
}
.alert-inverse {
    @include alert-variant($inverse, $inverse, #fff);
}

// Form Rounded
.form-control-rounded {
    border-radius: 100px;
}




.pd-white-box {
    padding: 24px;
    background-color: #fff;
    margin-top: 24px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
}

.form-horizontal {
    background-color: #fff;
    padding: 20px 0;
}

.form-horizontal .form-group {
    margin-bottom: 20px;
}
.form-horizontal .form-group {
    margin-right: -15px;
    margin-left: -15px;
}

.form-horizontal .control-label {
    color: #333!important;
}
.form-horizontal .control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
}
.ml25 {
    margin-left: 25px!important;
}

.w150 {
    width: 150px!important;
}
.inline-block {
    display: inline-block;
}
.input-sm {
    height: 32px;
    border-radius: 2px;
}
.input-sm {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.form-horizontal .form-group {
    margin-bottom: 20px;
}
.form-horizontal .form-group {
    margin-right: -15px;
    margin-left: -15px;
}

.form-horizontal .checkbox, .form-horizontal .checkbox-inline, .form-horizontal .radio, .form-horizontal .radio-inline {
    padding-top: 7px;
    margin-top: 0;
    margin-bottom: 0;
}
.power-check-all {
    color: #000;
    font-weight: bold;
    padding-bottom: 10px;
}
.radio-inline, .checkbox-inline {
    position: relative;
    padding-left: 25px;
}
.checkbox-inline, .radio-inline {
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer;
}

.checkbox-inline input[type=checkbox] {
    display: none;
}
.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio] {
    position: absolute;
    margin-top: 4px \9;
    margin-left: -20px;
}
input[type="radio"], input[type="checkbox"] {
    margin: 2px 0 0;
    line-height: normal;
    vertical-align: top;
}

.checkbox-inline input[type=checkbox]~.fake-checkbox {
    width: 16px;
    height: 16px;
    // background-image: url(../Images/fake-checkbox.png);
    background-size: 100%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 8px;
}

.checkbox-list li {
    list-style: none;
    border-top: 1px solid #DFE4EA;
    padding: 6px 0;
    float: left;
    width: 100%;
}
.checkbox-list .parent-check {
    font-weight: bold;
    float: left;
}
.form-horizontal .checkbox, .form-horizontal .checkbox-inline, .form-horizontal .radio, .form-horizontal .radio-inline {
    padding-top: 7px;
    margin-top: 0;
    margin-bottom: 0;
}
.checkbox-list li label {
    margin-right: 16px;
}
.radio-inline, .checkbox-inline {
    position: relative;
    padding-left: 25px;
}
.checkbox-inline, .radio-inline {
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer;
}
.checkbox-list li p {
    padding-left: 60px;
}
.form-horizontal .checkbox, .form-horizontal .checkbox-inline, .form-horizontal .radio, .form-horizontal .radio-inline {
    padding-top: 7px;
    margin-top: 0;
    margin-bottom: 0;
}
.checkbox-list li label {
    margin-right: 16px;
}
.radio-inline, .checkbox-inline {
    position: relative;
    padding-left: 25px;
   
}
.checkbox-inline, .radio-inline {
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer;
}
.checkbox-list li {
    list-style: none;
    border-top: 1px solid #DFE4EA;
    padding: 6px 0;
    float: left;
    width: 100%;
   
}

.list-style{
    list-style: none;
}

.text-fontw{

    // color: red;
    font-weight: bold;
}

.bor-bot{
width: 90%;
margin-left: 50px;
border-bottom: 1px solid #DFE4EA;;

}
.cph{
    cursor: pointer;
    text-decoration: none!important;
}