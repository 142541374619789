.l-background
{
    background: "img/login-background.png";
    background-repeat:no-repeat ;
    background-size:100% 100%;
    background-attachment: fixed;
}
.loadingModel-mask {
    // 让mask铺满整屏  
    position: fixed;  
    top: 0;  
    left: 0;  
    right: 0;  
    bottom: 0;  
    background: black;  
    opacity: 0.6;
    // 让mask覆盖在其它元素上面  
    z-index: 2000;
  }
  .loadingModel-container {
    // 让Modal的主体内容全局居中，通过position: fix以及top和left的50%让主体内容的左上角居中，再通过transform:translate(-50%, -50%)来让主体内容正确居中。
    position: fixed;  
    top: 50%;  
    left: 50%;  
    transform: translate(-50%, -50%);  
    // background: white; 
    min-width: 500px;  
    border-radius: 4px;
    // 设置主体内容的z-index高于mask的，从而可以覆盖mask  
    z-index: 2001;
  }