/* ========================================================================
     Component: cards
 ========================================================================== */

.card {
    margin-bottom: 1.25rem; //20px
}

//
// Adds support to use more components inside cards
//
.card {
    .table {
        margin-bottom: 0;
        >thead>tr>th {
            border-top: 0;
        }
    }
    &.card-transparent {
        border: 0;
        background-color: transparent;
        box-shadow: 0 0 0 #000;
        .card-header,
        .card-body {
            background-color: transparent;
            padding-left: 0;
            padding-right: 0;
        }
    }
}


.card-flat {
    margin: 0 !important;
    border: 0;
}

.card-columns-2 {
    column-count: 1;
    @include media-breakpoint-up(md) {
        column-count: 2;
    }
}

.card-tool {
    display: inline-block;
    color: #fff;
    text-align: center;
    &:hover {
        cursor: pointer;
    }
    > em + em {
        margin-left: 12px;
    }
}

.card-header {

    .card-default & {
        >.card-tool {
            color: #c1c2c3;
        }
    } // right floated labels adjust position
    >.badge.float-right {
        margin-top: 3px;
        +.badge.float-right {
            margin-right: 10px;
        }
    }
}

.card-footer {
    .pagination {
        margin: 0;
    }
    .radial-bar {
        margin-bottom: 0;
    }
    p {
        margin-bottom: 0;
    }
}

.overtime-box{
    margin-left: 125px;
    float: left;
    width: 500px;
    height: 30px;
    // background-color: yellow;
    line-height: 32px;
  }

  .overtime-box label{
      margin-top: 5px;
      margin-left: 20px;
      
  }

  .text-ml{
    //   color: red;
    float: left;
   margin-left: 10px;
  }

  .block-title {
    font-size: 15px;
    background-color: #f5f5f5;
    color: #262626;
    font-weight: 600;
    height: 32px;
    line-height: 32px;
    margin: 0 20px 15px 20px;
    padding: 0 10px;
}

.table-hd{
    margin: 0;
    margin-bottom: 20px;
    line-height: 30px;
    background: #f8f8f8;
    padding: 0 20px;
    font-size: 14px;
    font-weight: bold;
  
  
  }

  .input-ml{

margin-left: 130px;


  }

  .overtime-box-x{
float: left;
margin-top: 2px;
    width: 24px;
    height: 28px;
    // background-color: yellow;

  }

  .text-order {
    width: 80px;
    text-align: center;
    margin-left: 15px;
    // border-color: red;
    border:1px dashed rgb(179, 173, 173);
    
  }

  .input-ml-2x{



  }